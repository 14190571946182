import { useRouterStore } from "../stores/RouterStore.js";

export default defineNuxtRouteMiddleware((to, from) => {
    const requestedPage = Number(to.query.page || 0);
    if (!requestedPage) {
        return;
    }

    let paginator = null;

    if (to.name.startsWith('search')) {
        paginator = useCatalogStore().catalogResource?.paginator;
    } else {
        const routerStore = useRouterStore();
        const page = routerStore.getCurrentPage();

        paginator = page?.catalog?.paginator;
    }

    if (!paginator) {
        return;
    }

    if (requestedPage <= paginator.current_page) {
        return;
    }

    return navigateTo(
        {
            path: to.path,
            query: { ...to.query, page: paginator.current_page },
        },
        { redirectCode: 301, replace: true }
    );
});
